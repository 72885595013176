import styled from "@emotion/native";
import React, { FC, useEffect, useState } from "react";
import { Image } from "react-native";
import { IRightWrongImageActivityContent } from "../../types";
import colors from "../../colors";
import Icon from "../Icon";
import RightWrongDrawer from "../RightWrongDrawer";

export interface IRightWrongImageActivityProps {
  content: IRightWrongImageActivityContent;
  onCompleteChange: (complete: boolean, success: boolean) => void;
}

const RightWrongImageActivity: FC<IRightWrongImageActivityProps> = ({
  content,
  onCompleteChange,
}) => {
  const [wrongSelected, setWrongSelected] = useState(false);
  const [assignedValues, setAssignedValues] = useState<{
    [area: number]: boolean | undefined;
  }>({});

  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [imageOriginalWidth, setImageOriginalWidth] = useState(0);
  const [imageOriginalHeight, setImageOriginalHeight] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);

  useEffect(() => {
    const success = content.areas.every(
      (area, i) => area.isRight === assignedValues[i]
    );
    onCompleteChange(
      Object.values(assignedValues).filter((v) => v !== undefined).length ===
        content.areas.length,
      success
    );
  }, [assignedValues]);

  useEffect(() => {
    Image.getSize(
      content.background,
      (width, height) => {
        setImageOriginalWidth(width);
        setImageOriginalHeight(height);
      },
      () => null
    );
  }, [content.background]);

  useEffect(() => {
    if (imageOriginalWidth > 0 && imageOriginalHeight > 0) {
      const widthRatio = canvasWidth / imageOriginalWidth;
      const heightRatio = canvasHeight / imageOriginalHeight;

      const ratio = Math.min(widthRatio, heightRatio);
      setImageWidth(imageOriginalWidth * ratio);
      setImageHeight(imageOriginalHeight * ratio);
    }
  }, [canvasHeight, canvasWidth, imageOriginalHeight, imageOriginalWidth]);

  const onAreaPress = (area: number) => {
    if (assignedValues[area] === !wrongSelected) {
      setAssignedValues({
        ...assignedValues,
        [area]: undefined,
      });
    } else {
      setAssignedValues({
        ...assignedValues,
        [area]: !wrongSelected,
      });
    }
  };

  return (
    <Container>
      <Canvas
        onLayout={(e) => {
          const { width, height } = e.nativeEvent.layout;
          setCanvasHeight(height);
          setCanvasWidth(width);
        }}
      >
        <Background
          source={{ uri: content.background }}
          style={{ width: imageWidth, height: imageHeight }}
        />
        <Areas style={{ width: imageWidth, height: imageHeight }}>
          {content.areas.map((area, i) => (
            <Area
              key={i}
              style={{
                left: area.x * imageWidth,
                top: area.y * imageHeight,
                width: area.width * imageWidth,
                height: area.height * imageHeight,
                borderWidth: assignedValues[i] !== undefined ? 3 : 2,
                borderColor:
                  assignedValues[i] === true
                    ? colors.green
                    : assignedValues[i] === false
                    ? colors.red
                    : colors.lightGray,
              }}
              onPress={() => onAreaPress(i)}
            >
              {assignedValues[i] !== undefined && (
                <AreaCircle
                  style={{
                    [area.y * imageHeight > 15 ? "top" : "bottom"]: -15,
                    backgroundColor:
                      assignedValues[i] === true ? colors.green : colors.red,
                  }}
                >
                  <CircleIcon
                    name={assignedValues[i] === true ? "solved" : "close"}
                  />
                </AreaCircle>
              )}
            </Area>
          ))}
        </Areas>
      </Canvas>
      <RightWrongDrawer
        wrongSelected={wrongSelected}
        onChange={setWrongSelected}
      />
    </Container>
  );
};

export default RightWrongImageActivity;

const Container = styled.View`
  flex: 1;
`;

const Canvas = styled.View`
  flex: 1;
  background-color: ${colors.white};
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const Background = styled.Image`
  position: absolute;
`;

const Areas = styled.View`
  position: absolute;
  overflow: hidden;
`;

const Area = styled.TouchableOpacity`
  position: absolute;
  border-radius: 5px;
`;

const AreaCircle = styled.View`
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  transform: translateX(-15px);
`;

const CircleIcon = styled(Icon)`
  color: white;
  height: 24px;
  width: 24px;
`;
