import styled from "@emotion/native";
import React, { FC, useEffect, useState } from "react";
import colors from "../../colors";
import { ICatchActivityContent } from "../../types";
import CatchItem from "../CatchItem";

export interface ICatchActivityProps {
  content: ICatchActivityContent;
  onCompleteChange: (complete: boolean, success: boolean) => void;
}

const CatchActivity: FC<ICatchActivityProps> = ({
  content,
  onCompleteChange,
}) => {
  const [caughtItems, setCaughtItems] = useState<number[]>([]);
  const [canvasSize, setCanvasSize] = useState<[number, number]>([0, 0]);
  const { items } = content;

  useEffect(() => {
    const rightItems = items.filter((i) => i.isRight);
    const success = items.every((item, i) =>
      item.isRight ? caughtItems.includes(i) : !caughtItems.includes(i)
    );
    onCompleteChange(caughtItems.length >= 1, success);
  }, [caughtItems]);

  return (
    <Container>
      <Canvas
        onLayout={(e) => {
          const { width, height } = e.nativeEvent.layout;
          setCanvasSize([width, height]);
        }}
      >
        {items.map(
          (item, i) =>
            !caughtItems.includes(i) && (
              <CatchItem
                key={i}
                image={item.image}
                canvasSize={canvasSize}
                onPress={() => setCaughtItems([...caughtItems, i])}
              />
            )
        )}
      </Canvas>
      <Drawer>
        {caughtItems.map((index) => (
          <DrawerButton
            key={`drawer-button-${index}`}
            onPress={() =>
              setCaughtItems(caughtItems.filter((i) => i !== index))
            }
            onLongPress={() =>
              setCaughtItems(caughtItems.filter((i) => i !== index))
            }
          >
            <DrawerImage source={{ uri: items[index].image }} />
          </DrawerButton>
        ))}
      </Drawer>
    </Container>
  );
};

export default CatchActivity;

const Container = styled.View`
  flex: 1;
`;

const Canvas = styled.View`
  flex: 1;
  overflow: hidden;
`;

const Drawer = styled.View`
  height: 80px;
  background-color: ${colors.black};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const DrawerButton = styled.TouchableOpacity`
  margin: 0 5px;
  border-radius: 10px;
  background-color: ${colors.white};
  width: 64px;
  height: 64px;
  align-items: center;
  justify-content: center;
`;

const DrawerImage = styled.Image`
  width: 54px;
  height: 54px;
  resize-mode: contain;
`;
