import React, { FC } from "react";
import { Text as NativeText, TextProps } from "react-native";
import colors from "../colors";

export interface ITextProps extends TextProps {
  bold?: boolean;
  light?: boolean;
}

const Text: FC<ITextProps> = ({ bold, light, ...props }) => {
  const color = colors.black;
  const fontSize = 18;
  const fontFamily = bold
    ? "VodafoneRg-Bold"
    : light
    ? "VodafoneLt-Regular"
    : "VodafoneRg-Regular";
  return (
    <NativeText
      {...props}
      style={[{ fontFamily, fontSize, color }, props.style]}
    />
  );
};

export default Text;
