import React, { FC } from "react";
import { Platform, SafeAreaView, StatusBar } from "react-native";

const SafeArea: FC = ({ children }) => (
  <SafeAreaView
    style={{
      flex: 1,
      marginTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
    }}
  >
    {children}
  </SafeAreaView>
);

export default SafeArea;
