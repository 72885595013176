const colors = {
  albergine: "#5e2750",
  black: "#333333",
  blue: "#00b0ca",
  darkGray: "#666666",
  gray: "#999999",
  green: "#428600",
  lightGray: "#cccccc",
  lightGreen: "#abb400",
  orange: "#eb9700",
  purple: "#9c2aa0",
  red: "#e00000",
  turquoise: "#007c92",
  white: "#ffffff",
  yellow: "#fecb00",
};

export default colors;
