import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

function SvgPadlock(props: SvgProps) {
  return (
    <Svg viewBox="0 0 40 40" {...props}>
      <Path
        fill="currentColor"
        fillRule="evenodd"
        d="M19.167 20.917a.83.83 0 01.833-.829.83.83 0 01.833.829v9.947c0 .458-.373.83-.833.83a.832.832 0 01-.833-.83v-9.947zM10 16.772c-.92 0-1.667.743-1.667 1.658v14.92c0 .917.747 1.659 1.667 1.659h20c.92 0 1.667-.742 1.667-1.658V18.43c0-.916-.747-1.659-1.667-1.659H10zm1.667-1.658v-3.491c0-4.578 3.73-8.29 8.333-8.29 4.602 0 8.333 3.712 8.333 8.29v3.491H30a3.325 3.325 0 013.333 3.316v14.92A3.325 3.325 0 0130 36.667H10a3.325 3.325 0 01-3.333-3.316V18.43A3.325 3.325 0 0110 15.113h1.667zm1.666 0h13.334v-3.491c0-3.663-2.985-6.632-6.667-6.632s-6.667 2.97-6.667 6.632v3.491z"
      />
    </Svg>
  );
}

export default SvgPadlock;
