import styled from "@emotion/native";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import React, { FC, useState } from "react";
import { View } from "react-native";
import colors from "../colors";
import { Button, ExitModal, SafeArea, Text } from "../components";
import { useBreakpoints } from "../lib/breakpoints";
import { useTranslate } from "../lib/translate";
import { StackParamList } from "../types";

const GoodbyeScreen: FC = () => {
  const t = useTranslate();
  const { isDesktop } = useBreakpoints();
  const navigation = useNavigation();
  const { params } = useRoute<RouteProp<StackParamList, "SelectRewards">>();
  const { playersNumber } = params;
  const [exitVisible, setExitVisible] = useState<boolean>(false);

  return (
    <SafeArea>
      <Container>
        <View
          style={{
            height: isDesktop ? 380 : 229,
            width: isDesktop ? 380 : 229,
          }}
        >
          <DiodiKeyImage
            source={require("../assets/images/diodi-explorer.png")}
          />
        </View>
        <Title>{t("goodbye.text")}</Title>
        <Button onPress={() => navigation.navigate("Landing")}>
          {t("goodbye.button")}
        </Button>
      </Container>
      <ExitModal
        plural={playersNumber > 1}
        visible={exitVisible}
        setVisible={setExitVisible}
      />
    </SafeArea>
  );
};

export default GoodbyeScreen;

const Container = styled.View`
  align-items: center;
  flex: 1;
  justify-content: center;
`;

const DiodiKeyImage = styled.Image`
  flex: 1;
  resize-mode: contain;
  width: auto;
`;

const Title = styled(Text)`
  color: ${colors.red};
  font-size: 26px;
  margin-bottom: 30px;
  margin-top: 10px;
`;
