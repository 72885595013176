import * as React from "react";
import Svg, { G, Path, SvgProps } from "react-native-svg";

function SvgAdd(props: SvgProps) {
  return (
    <Svg viewBox="0 0 64 64" {...props}>
      <G fill="none" fillRule="evenodd">
        <Path
          fill="currentColor"
          d="M46.667 30.667H33.333V17.333a1.333 1.333 0 00-2.666 0v13.334H17.333a1.333 1.333 0 000 2.666h13.334v13.334a1.333 1.333 0 002.666 0V33.333h13.334a1.333 1.333 0 000-2.666"
        />
      </G>
    </Svg>
  );
}

export default SvgAdd;
