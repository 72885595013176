import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";
import React from "react";
import { Platform } from "react-native";
import { StatusBar } from "expo-status-bar";
import { NavigationContainer } from "@react-navigation/native";
import {
  CardStyleInterpolators,
  createStackNavigator,
} from "@react-navigation/stack";
import colors from "./colors";
import AndroidVersionModal from "./components/AndroidVersionModal";
import { getUri } from "./config";
import { TranslateConsumer, TranslateProvider } from "./lib/translate";
import {
  Activities,
  Beginning,
  Ending,
  EnterAge,
  EnterKey,
  Goodbye,
  Help,
  Instructions,
  Landing,
  SelectPlayers,
  SelectRewards,
  ShowRewards,
  UnlockRewards,
} from "./screens";
import { StackParamList } from "./types";

const Stack = createStackNavigator<StackParamList>();

const client = new ApolloClient({
  uri: getUri(),
  cache: new InMemoryCache(),
});

function App() {
  const [fontsLoaded] = useFonts({
    "VodafoneRg-Regular": require("./assets/fonts/VodafoneRg-Regular.ttf"),
    "VodafoneRg-Bold": require("./assets/fonts/VodafoneRg-Bold.ttf"),
    "VodafoneLt-Regular": require("./assets/fonts/VodafoneLt-Regular.ttf"),
  });

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  return (
    <ApolloProvider client={client}>
      <TranslateProvider>
        <StatusBar backgroundColor="transparent" />
        {Platform.OS === "android" && <AndroidVersionModal />}
        <TranslateConsumer>
          {({ translateFn: t }) => {
            const options = { options: { title: t("general.title") } };
            return (
              <NavigationContainer>
                <Stack.Navigator
                  screenOptions={{
                    cardStyle: { backgroundColor: colors.white },
                    cardStyleInterpolator:
                      CardStyleInterpolators.forHorizontalIOS,
                    headerShown: false,
                  }}
                >
                  <Stack.Screen
                    name="Landing"
                    component={Landing}
                    {...options}
                  />
                  <Stack.Screen
                    name="EnterKey"
                    component={EnterKey}
                    {...options}
                  />
                  <Stack.Screen name="Help" component={Help} {...options} />
                  <Stack.Screen
                    name="EnterAge"
                    component={EnterAge}
                    {...options}
                  />
                  <Stack.Screen
                    name="SelectPlayers"
                    component={SelectPlayers}
                    {...options}
                  />
                  <Stack.Screen
                    name="Instructions"
                    component={Instructions}
                    {...options}
                  />
                  <Stack.Screen
                    name="Beginning"
                    component={Beginning}
                    {...options}
                  />
                  <Stack.Screen
                    name="Activities"
                    component={Activities}
                    {...options}
                  />
                  <Stack.Screen name="Ending" component={Ending} {...options} />
                  <Stack.Screen
                    name="UnlockRewards"
                    component={UnlockRewards}
                    {...options}
                  />
                  <Stack.Screen
                    name="SelectRewards"
                    component={SelectRewards}
                    {...options}
                  />
                  <Stack.Screen
                    name="ShowRewards"
                    component={ShowRewards}
                    {...options}
                  />
                  <Stack.Screen
                    name="Goodbye"
                    component={Goodbye}
                    {...options}
                  />
                </Stack.Navigator>
              </NavigationContainer>
            );
          }}
        </TranslateConsumer>
      </TranslateProvider>
    </ApolloProvider>
  );
}

export default App;
