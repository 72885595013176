import * as React from "react";
import Svg, { G, Path, SvgProps } from "react-native-svg";

function SvgInfoCircle(props: SvgProps) {
  return (
    <Svg viewBox="0 0 32 32" {...props}>
      <G fill="none" fillRule="evenodd">
        <Path d="M0 0h32v32H0z" />
        <Path
          fill="currentColor"
          d="M15.667 11.167a1 1 0 110-2 1 1 0 010 2zM16 28c6.628 0 12-5.372 12-12 0-6.627-5.372-12-12-12C9.373 4 4 9.373 4 16c0 6.628 5.373 12 12 12zm0-25.333c7.364 0 13.333 5.97 13.333 13.333 0 7.364-5.97 13.333-13.333 13.333-7.364 0-13.333-5.97-13.333-13.333C2.667 8.636 8.637 2.667 16 2.667zm1.333 18.666a.667.667 0 110 1.334h-2.666a.667.667 0 010-1.334h.666v-6.666h-.666a.667.667 0 010-1.334H16c.368 0 .667.299.667.667v7.333h.666z"
        />
      </G>
    </Svg>
  );
}

export default SvgInfoCircle;
