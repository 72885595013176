import styled from "@emotion/native";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import React, { FC, useState } from "react";
import colors from "../colors";
import { Button, Header, Icon, SafeArea, Text } from "../components";
import { useBreakpoints } from "../lib/breakpoints";
import { useTranslate } from "../lib/translate";
import { Age, StackParamList } from "../types";

const EnterAgeScreen: FC = () => {
  const t = useTranslate();
  const { isDesktop } = useBreakpoints();
  const navigation = useNavigation();
  const { params } = useRoute<RouteProp<StackParamList, "EnterAge">>();
  const [age, setAge] = useState<Age>();

  return (
    <SafeArea>
      <Header
        leftButton={{
          iconName: "arrow-circle",
          onPress: () => navigation.goBack(),
        }}
      />
      <Container>
        <Title>{t("enter-age.text")}</Title>
        <ButtonGroup>
          <AgeButton
            onPress={() => setAge(Age.sixToEight)}
            style={{
              borderColor:
                age === Age.sixToEight ? colors.turquoise : colors.darkGray,
              borderWidth: age === Age.sixToEight ? 4 : 1,
              width: isDesktop ? 280 : 229,
            }}
          >
            <AgeIcon name="group" />
            <AgeText light>{t("enter-age.age-younger")}</AgeText>
          </AgeButton>
          <AgeButton
            onPress={() => setAge(Age.nineToTwelve)}
            style={{
              borderColor:
                age === Age.nineToTwelve ? colors.turquoise : colors.darkGray,
              borderWidth: age === Age.nineToTwelve ? 4 : 1,
              width: isDesktop ? 280 : 229,
            }}
          >
            <AgeIcon name="group" />
            <AgeText light>{t("enter-age.age-older")}</AgeText>
          </AgeButton>
        </ButtonGroup>
        <Button
          disabled={!age}
          iconName="arrow-small"
          onPress={() =>
            navigation.navigate("SelectPlayers", { age, ...params })
          }
        >
          {t("general.continue-button")}
        </Button>
      </Container>
    </SafeArea>
  );
};

export default EnterAgeScreen;

const AgeButton = styled.TouchableOpacity`
  align-items: center;
  border-radius: 10px;
  height: 146px;
  justify-content: center;
  margin: 30px 10px;
`;

const AgeText = styled(Text)`
  font-size: 26px;
  margin-top: 10px;
`;

const AgeIcon = styled(Icon)`
  height: 64px;
  width: 64px;
`;

const ButtonGroup = styled.View`
  flex-direction: row;
`;

const Container = styled.View`
  align-items: center;
  flex: 1;
  justify-content: center;
`;

const Title = styled(Text)`
  color: ${colors.red};
  font-size: 26px;
  margin-top: 10px;
`;
