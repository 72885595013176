import styled from "@emotion/native";
import React, { FC, useEffect, useRef, useState } from "react";
import { Animated } from "react-native";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import colors from "../colors";
import { useBreakpoints } from "../lib/breakpoints";
import { useTranslate } from "../lib/translate";
import {
  Activity,
  Button,
  ExitModal,
  Icon,
  PlayerAvatar,
  SafeArea,
  ScrollableText,
  Text,
} from "../components";
import { StackParamList } from "../types";

const ActivitiesScreen: FC = () => {
  const t = useTranslate();
  const { isTall } = useBreakpoints();
  const navigation = useNavigation();
  const [exitVisible, setExitVisible] = useState<boolean>(false);
  const [activePlayer, setActivePlayer] = useState<number>(0);
  const [currentActivity, setCurrentActivity] = useState<number>(0);
  const { params } = useRoute<RouteProp<StackParamList, "Activities">>();
  const { activities, adventure, age, key, players } = params;
  const [canAdvance, setCanAdvance] = useState<boolean>(false);
  const activeColor = players[activePlayer].color;

  const activityAnim = useRef(new Animated.Value(currentActivity)).current;
  const results = useRef<boolean[]>([]);

  useEffect(() => {
    setCanAdvance(false);
    Animated.timing(activityAnim, {
      duration: 300,
      toValue: currentActivity,
      useNativeDriver: true,
    }).start();
  }, [currentActivity]);

  const onNext = () => {
    if (currentActivity < activities.length - 1) {
      setCurrentActivity(currentActivity + 1);
      setActivePlayer((currentActivity + 1) % players.length);
    } else {
      navigation.navigate("Ending", {
        activitiesNumber: activities.length,
        adventure,
        playersNumber: players.length,
        success: results.current.filter((r) => r === true).length,
        activities,
        age,
        key,
        results: results.current,
      });
    }
  };

  const triangleAnim = Animated.multiply(activityAnim, 30);

  const activity = activities[currentActivity];

  if (!activity) {
    return null;
  }

  const onCompleteChange = (complete: boolean, success: boolean) => {
    results.current[currentActivity] = success;
    setCanAdvance(complete);
  };

  return (
    <SafeArea>
      <Container>
        <Header style={{ height: isTall ? 120 : 80 }}>
          <ExitButton onPress={() => setExitVisible(true)}>
            <ExitIcon name="exit-circle" />
          </ExitButton>
          <DiodiBalloon
            style={{ height: isTall ? 120 : 80, width: isTall ? 120 : 80 }}
          >
            <DiodiImage
              style={{ height: isTall ? 120 : 80, width: isTall ? 120 : 80 }}
              source={require("../assets/images/avatar-diodi.png")}
            />
          </DiodiBalloon>
          <Description
            style={{ height: isTall ? 120 : 80 }}
            source={require("../assets/images/avatar-diodi.png")}
          >
            <DescriptionText text={activity?.description} />
            <BalloonTip source={require("../assets/images/balloon-tip.png")} />
          </Description>
        </Header>
        <Content
          style={{
            borderColor: activeColor,
          }}
        >
          <Players
            style={{
              backgroundColor: activeColor,
            }}
          >
            {players.length > 1 &&
              (activeColor === colors.yellow ? (
                <TurnTextBlack bold>{t("activities.turn")}</TurnTextBlack>
              ) : (
                <TurnText bold>{t("activities.turn")}</TurnText>
              ))}
            {players.map((player, i) => (
              <PlayerAvatar key={i} active={activePlayer === i} {...player} />
            ))}
          </Players>
          <Activity
            key={currentActivity}
            activity={activity}
            age={age}
            onCompleteChange={onCompleteChange}
          />
        </Content>
        <Bottom>
          <Dots>
            {activities.map((activity, i) => (
              <Dot
                key={`dot-${i}`}
                style={{
                  backgroundColor:
                    i === currentActivity ? colors.red : colors.lightGray,
                }}
              />
            ))}
          </Dots>
          <Button onPress={onNext} disabled={!canAdvance}>
            <NextIcon name="arrow" />
          </Button>
          <Triangle
            style={{
              borderTopColor: activeColor,
              transform: [
                {
                  translateX: triangleAnim,
                },
              ],
            }}
          />
        </Bottom>
      </Container>
      <ExitModal
        plural={players.length > 1}
        visible={exitVisible}
        setVisible={setExitVisible}
      />
    </SafeArea>
  );
};

export default ActivitiesScreen;

const Container = styled.View`
  flex: 1;
  padding: 20px;
`;

const Header = styled.View`
  flex-direction: row;
  margin-bottom: 10px;
`;

const ExitButton = styled.TouchableOpacity`
  margin-right: 10px;
`;

const DiodiBalloon = styled.View`
  border-color: ${colors.red};
  border-width: 3px;
  border-radius: 60px;
  overflow: hidden;
  margin-right: 16px;
`;

const DiodiImage = styled.Image`
  resize-mode: cover;
`;

const Description = styled.View`
  border-color: ${colors.red};
  border-width: 3px;
  flex: 1;
  border-radius: 30px;
  padding: 7px;
  flex-direction: row;
`;

const DescriptionText = styled(ScrollableText)`
  flex: 1;
`;

const BalloonTip = styled.Image`
  position: absolute;
  bottom: -5px;
  left: -19px;
  width: 34px;
  height: 44px;
`;

const ExitIcon = styled(Icon)`
  width: 64px;
  height: 64px;
  color: ${colors.red};
`;

const Content = styled.View`
  flex: 1;
  margin-bottom: 10px;
  border-radius: 10px;
  border-width: 3px;
  flex-direction: row;
  overflow: hidden;
`;

const Players = styled.View`
  width: 140px;
  align-items: center;
`;

const TurnText = styled(Text)`
  margin-top: 10px;
  line-height: 40px;
  color: ${colors.white};
`;

const TurnTextBlack = styled(Text)`
  margin-top: 10px;
  line-height: 40px;
  color: ${colors.black};
`;

const Bottom = styled.View`
  flex-direction: row;
`;

const Dots = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-left: 16px;
`;

const Dot = styled.View`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-right: 10px;
`;

const Triangle = styled(Animated.View)`
  position: absolute;
  top: -11px;
  margin-left: 10px;
  border-top-width: 16px;
  border-right-width: 16px;
  border-bottom-width: 0px;
  border-left-width: 16px;
  border-color: transparent;
`;

const NextIcon = styled(Icon)`
  margin: -10px 0;
  width: 40px;
  height: 40px;
  color: white;
`;
