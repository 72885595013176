import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

function SvgArrow(props: SvgProps) {
  return (
    <Svg viewBox="0 0 40 40" {...props}>
      <Path
        fill="currentColor"
        fillRule="evenodd"
        d="M27.046 9.058c-.473.5-.45 1.29.053 1.761l8.498 7.977H1.25c-.69 0-1.25.558-1.25 1.246s.56 1.246 1.25 1.246h34.352L27.19 29.18a1.241 1.241 0 00-.053 1.76 1.254 1.254 0 001.767.054l10.703-10.043c.262-.246.393-.577.393-.908v-.002c0-.385-.175-.728-.45-.957L28.813 9.005a1.251 1.251 0 00-1.767.053"
      />
    </Svg>
  );
}

export default SvgArrow;
