import styled from "@emotion/native";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import React, { FC } from "react";
import colors from "../colors";
import { beginningText } from "../config";
import { Button, Header, SafeArea, Text } from "../components";
import { useBreakpoints } from "../lib/breakpoints";
import { useTranslate } from "../lib/translate";
import { StackParamList } from "../types";
import { Dimensions, ImageBackground } from "react-native";

const BeginningScreen: FC = () => {
  const t = useTranslate();
  const { isDesktop } = useBreakpoints();
  const navigation = useNavigation();
  const { params } = useRoute<RouteProp<StackParamList, "Beginning">>();
  const adventure = params.adventure;

  const height = Dimensions.get("window").height;
  const width = Dimensions.get("window").width;

  const imageBackground =
    adventure === "initial"
      ? require("../assets/images/beginning_adventure_1.png")
      : require("../assets/images/beginning_adventure_2.png");

  return (
    <ImageBackground source={imageBackground} style={{ height, width }}>
      <SafeArea>
        <Header
          leftButton={{
            iconName: "arrow-circle",
            onPress: () => navigation.goBack(),
          }}
        />
        <FooterWrap style={{ padding: isDesktop ? 40 : 20 }}>
          <Footer style={{ elevation: 3 }}>
            <StyledText>{t(beginningText[adventure])}</StyledText>
            <Button
              iconName="arrow-small"
              onPress={() => navigation.navigate("Activities", { ...params })}
            >
              {t("general.continue-button")}
            </Button>
          </Footer>
        </FooterWrap>
      </SafeArea>
    </ImageBackground>
  );
};

export default BeginningScreen;

const Footer = styled.View`
  align-items: center;
  background-color: ${colors.white};
  border-radius: 10px;
  flex: 1;
  flex-direction: row;
  padding: 20px;
`;

const FooterWrap = styled.View`
  flex-direction: row;
  bottom: 0;
  position: absolute;
`;

const StyledText = styled(Text)`
  flex-shrink: 1;
  margin-right: 20px;
`;
