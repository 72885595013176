import styled from "@emotion/native";
import React, { FC, useEffect, useRef } from "react";
import { Animated } from "react-native";
import { useBreakpoints } from "../lib/breakpoints";
import { IPlayer } from "../types";
import colors from "../colors";

export interface IPlayerAvatarProps extends IPlayer {
  active?: boolean;
}

const PlayerAvatar: FC<IPlayerAvatarProps> = ({ active, ...player }) => {
  const { isDesktop } = useBreakpoints();

  const activeSize = isDesktop ? 100 : 64;
  const normalSize = isDesktop ? 60 : 40;

  const sizeAnim = useRef(new Animated.Value(active ? activeSize : normalSize))
    .current;

  useEffect(() => {
    Animated.timing(sizeAnim, {
      duration: 300,
      toValue: active ? activeSize : normalSize,
      useNativeDriver: false,
    }).start();
  }, [active]);

  return (
    <Container
      style={{
        borderColor: player.color,
        width: sizeAnim,
        height: sizeAnim,
        borderRadius: sizeAnim,
      }}
    >
      <AvatarImage source={player.image} />
    </Container>
  );
};

export default PlayerAvatar;

const Container = styled(Animated.View)`
  border-width: 3px;
  overflow: hidden;
  background-color: ${colors.white};
  margin: 10px 0;
`;

const AvatarImage = styled.Image`
  width: 100%;
  height: 100%;
  resize-mode: cover;
`;
