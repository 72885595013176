import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

function SvgMessages(props: SvgProps) {
  return (
    <Svg viewBox="0 0 40 40" {...props}>
      <Path
        fill="currentColor"
        fillRule="evenodd"
        d="M30.833 23.545c.46 0 .834.377.834.843a.839.839 0 01-.834.843h-10a.838.838 0 01-.833-.843c0-.466.373-.843.833-.843h10zm0-5.058a.84.84 0 01.834.843.838.838 0 01-.834.843h-10A.838.838 0 0120 19.33c0-.465.373-.843.833-.843h10zM35 26.917V16.8c0-.93-.746-1.686-1.667-1.686h-15c-.92 0-1.666.755-1.666 1.686v10.116c0 .93.746 1.685 1.666 1.685h9.117c.22 0 .433.09.59.247l3.233 3.271v-2.675c0-.465.373-.843.833-.843h1.227c.92 0 1.667-.754 1.667-1.685zM15 16.8c0-1.862 1.492-3.372 3.333-3.372h5V8.372c0-.931-.746-1.686-1.666-1.686h-15C5.747 6.686 5 7.44 5 8.372v10.115c0 .931.746 1.686 1.667 1.686h1.225c.46 0 .833.377.833.843v2.675l3.236-3.271a.828.828 0 01.589-.247H15v-3.372zm18.333-3.372c1.841 0 3.334 1.51 3.334 3.372v10.116c0 1.862-1.493 3.371-3.334 3.371h-.394v3.867c0 .751-.897 1.127-1.422.597l-4.412-4.464h-8.772c-1.84 0-3.333-1.51-3.333-3.371v-5.058h-2.105l-4.414 4.463c-.525.53-1.423.155-1.423-.596v-3.867h-.391c-1.841 0-3.334-1.51-3.334-3.372V8.372C3.333 6.51 4.826 5 6.667 5h15C23.507 5 25 6.51 25 8.372v5.057h8.333z"
      />
    </Svg>
  );
}

export default SvgMessages;
