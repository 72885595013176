import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

function SvgExitCircle(props: SvgProps) {
  return (
    <Svg viewBox="0 0 40 40" {...props}>
      <Path
        fill="currentColor"
        fillRule="evenodd"
        d="M20 35c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15zm0-31.667c9.205 0 16.667 7.462 16.667 16.667 0 9.205-7.462 16.667-16.667 16.667-9.205 0-16.667-7.462-16.667-16.667 0-9.205 7.462-16.667 16.667-16.667zm2.913 23.527h3.677V13.14h-3.677v13.72zm-1.667 2.078V11.062l-7.836 1.9v14.076l7.836 1.9zm6.177-17.465c.46 0 .834.373.834.834v15.387c0 .46-.373.833-.834.833h-4.51v1.47a.832.832 0 01-1.03.81l-9.503-2.303a.834.834 0 01-.637-.81V12.307c0-.385.263-.72.637-.81l9.503-2.304a.836.836 0 011.03.81v1.47h4.51zm-8.255 9.36a.832.832 0 110-1.662.832.832 0 010 1.661z"
      />
    </Svg>
  );
}

export default SvgExitCircle;
