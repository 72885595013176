import React, { FC, useEffect, useState } from "react";
import { ITestActivityContent } from "../../types";
import Options from "../Options";

export interface ITestActivityProps {
  content: ITestActivityContent;
  onCompleteChange: (complete: boolean, success: boolean) => void;
}

const TestActivity: FC<ITestActivityProps> = ({
  content,
  onCompleteChange,
}) => {
  const [selectedOption, setSelectedOption] = useState(-1);
  const { options } = content;

  useEffect(() => {
    onCompleteChange(
      selectedOption >= 0,
      selectedOption === content.correctOption
    );
  }, [selectedOption]);

  return (
    <Options
      options={options.map((option, i) => ({
        ...option,
        selected: selectedOption === i,
      }))}
      onOptionPress={(index) =>
        setSelectedOption(selectedOption === index ? -1 : index)
      }
    />
  );
};

export default TestActivity;
