import React, { FC, useEffect } from "react";
import { BackHandler, Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";
import DialogModal from "./DialogModal";
import { useTranslate } from "../lib/translate";

export interface IExitModalProps {
  plural?: boolean;
  setVisible: (visible: boolean) => void;
  visible: boolean;
}

const ExitModal: FC<IExitModalProps> = ({ plural, setVisible, visible }) => {
  const t = useTranslate();
  const navigation = useNavigation();

  const backPress = () => {
    setVisible(true);
    return true;
  };

  const beforeClose = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = "";
    return;
  };

  useEffect(() => {
    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      backPress
    );
    Platform.OS === "web" &&
      !!window &&
      (window.addEventListener("beforeunload", beforeClose, true),
      window.addEventListener("popstate", beforeClose, true));

    return () => {
      backHandler.remove();
      Platform.OS === "web" &&
        !!window &&
        (window.removeEventListener("beforeunload", beforeClose, true),
        window.addEventListener("popstate", beforeClose, true));
    };
  }, []);

  return (
    <DialogModal
      visible={visible}
      message={t(
        `general.exit-modal.message.${plural ? "plural" : "singular"}`
      )}
      title={t(`general.exit-modal.title.${plural ? "plural" : "singular"}`)}
      okText={t("general.exit-modal.exit")}
      onOk={() => {
        setVisible(false);
        navigation.navigate("Landing");
      }}
      cancelText={t("general.exit-modal.stay")}
      onCancel={() => setVisible(false)}
    />
  );
};

export default ExitModal;
