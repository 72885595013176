import * as React from "react";
import Svg, { G, Path, SvgProps } from "react-native-svg";

function SvgArrowSmall(props: SvgProps) {
  return (
    <Svg viewBox="0 0 26 26" {...props}>
      <G fill="none" fillRule="evenodd" transform="translate(-99 -9)">
        <Path
          fill="currentColor"
          fillRule="evenodd"
          d="M120.984 21.458l-8.284-8.283a.543.543 0 01.766-.766l9.209 9.208a.543.543 0 010 .766l-9.209 9.208a.54.54 0 11-.766-.766l8.284-8.283h-19.276a.542.542 0 010-1.084h19.276z"
        />
      </G>
    </Svg>
  );
}

export default SvgArrowSmall;
