import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

function SvgArrowCircle(props: SvgProps) {
  return (
    <Svg viewBox="0 0 64 64" {...props}>
      <Path
        fill="currentColor"
        fillRule="evenodd"
        d="M32 56c13.255 0 24-10.745 24-24S45.255 8 32 8 8 18.745 8 32s10.745 24 24 24zm0-50.667c14.728 0 26.667 11.94 26.667 26.667 0 14.728-11.94 26.667-26.667 26.667-14.728 0-26.667-11.94-26.667-26.667C5.333 17.272 17.273 5.333 32 5.333zm12.89 25.333a1.333 1.333 0 110 2.667H22.328l6.733 6.732c.52.521.52 1.365 0 1.886-.52.52-1.365.52-1.886 0l-9.008-9.009a1.334 1.334 0 010-1.885l9.007-9.008a1.335 1.335 0 011.886 0c.52.52.52 1.365 0 1.886l-6.732 6.731H44.89z"
      />
    </Svg>
  );
}

export default SvgArrowCircle;
