import * as React from "react";
import Svg, { G, Path, SvgProps } from "react-native-svg";

function SvgAngle(props: SvgProps) {
  return (
    <Svg viewBox="0 0 40 40" {...props}>
      <G transform="translate(5 12)">
        <Path
          fill="currentColor"
          fillRule="evenodd"
          d="M1.423.327A.833.833 0 00.244 1.506l14.167 14.167a.833.833 0 001.178 0L29.756 1.506A.833.833 0 0028.577.327L15 13.905 1.423.327z"
        />
      </G>
    </Svg>
  );
}

export default SvgAngle;
