import * as React from "react";
import Svg, { G, Path, SvgProps } from "react-native-svg";

function SvgWarning(props: SvgProps) {
  return (
    <Svg viewBox="0 0 32 32" {...props}>
      <G fill="none" fillRule="evenodd">
        <Path d="M0 0h32v32H0z" />
        <Path
          fill="currentColor"
          d="M25.539 27.183c2.255 0 3.11-1.56 1.953-3.58l-9.539-17.31c-1.12-1.985-2.746-1.96-3.905.004L4.51 23.603c-1.156 2.017-.298 3.58 1.953 3.58H25.54zm3.116-4.224c1.644 2.877.183 5.541-3.116 5.541H6.462c-3.295 0-4.761-2.67-3.119-5.536l9.539-17.305c1.678-2.854 4.602-2.904 6.24 0l9.533 17.3zm-12.654-2.278a.662.662 0 01-.667-.658v-9.3c0-.364.298-.66.667-.66.368 0 .666.296.666.66v9.3a.662.662 0 01-.666.658zm0 1.564c.552 0 1 .442 1 .988a.994.994 0 01-1 .987c-.553 0-1-.442-1-.987a.993.993 0 011-.988z"
        />
      </G>
    </Svg>
  );
}

export default SvgWarning;
