import styled from "@emotion/native";
import { useNavigation, useRoute, RouteProp } from "@react-navigation/native";
import React, { FC, useEffect, useState } from "react";
import {
  Animated,
  Dimensions,
  Image,
  ImageBackground,
  ImageSourcePropType,
  Platform,
  View,
} from "react-native";
import colors from "../colors";
import { Button, ExitModal, SafeArea, Text } from "../components";
import { cards, diplomas } from "../config";
import { useBreakpoints } from "../lib/breakpoints";
import { useTranslate } from "../lib/translate";
import { StackParamList } from "../types";

const ShowRewards: FC = () => {
  const t = useTranslate();
  const { isDesktop } = useBreakpoints();
  const navigation = useNavigation();
  const { params } = useRoute<RouteProp<StackParamList, "ShowRewards">>();
  const [exitVisible, setExitVisible] = useState<boolean>(false);
  const [rewards, setRewards] = useState<ImageSourcePropType[]>([]);
  const { adventure, playersNumber } = params;

  useEffect(() => {
    const newSources = params.selected.map((reward: number) =>
      adventure === "initial" ? cards[reward].source : diplomas[reward].source
    );
    setRewards(newSources);
  }, [params.selected]);

  const imageBackground = require("../assets/images/background_award.jpg");

  const setWidthImage = (): number => {
    if (isDesktop) {
      if (params.adventure === "initial") {
        return 200;
      }
      return rewards.length === 1 ? 400 : 300;
    } else {
      if (params.adventure === "initial") {
        return 200;
      }
      return rewards.length === 3 ? 210 : 300;
    }
  };
  const setHeightImage = (): number => {
    if (isDesktop) {
      if (params.adventure === "initial") {
        return 270;
      }
      return rewards.length === 1 ? 266 : 200;
    } else {
      if (params.adventure === "initial") {
        return 270;
      }
      return rewards.length === 3 ? 140 : 200;
    }
  };

  return (
    <SafeArea>
      <Container>
        <Title
          style={{
            minWidth: 728,
            width: Dimensions.get("window").width - 40,
            maxWidth: 983,
          }}
        >
          {t(
            `send-rewards.text.${adventure}.${
              playersNumber > 1 ? "plural" : "singular"
            }`
          )}
        </Title>
        <ContentWrapper
          style={{
            marginRight: 20,
            marginLeft: 20,
            minWidth: 728,
            width: Dimensions.get("window").width - 40,
            maxWidth: 983,
            maxHeight: isDesktop ? 403 : 323,
          }}
        >
          <ImageBackground
            source={imageBackground}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
            imageStyle={{
              borderRadius: 10,
            }}
            borderRadius={10}
            resizeMode="cover"
          >
            {rewards.map((reward, index) => (
              <Image
                key={index}
                source={reward}
                style={{
                  width: setWidthImage(),
                  height: setHeightImage(),
                  marginRight: index === params.selected.length - 1 ? 0 : 20,
                  resizeMode: "contain",
                }}
                resizeMode="contain"
              />
            ))}
          </ImageBackground>
        </ContentWrapper>
        <Button
          iconName="arrow-small"
          onPress={() => navigation.navigate("Goodbye", { playersNumber })}
        >
          {t(
            `send-rewards.text.${adventure}.${
              playersNumber > 1 ? "button-plural" : "button-singular"
            }`
          )}
        </Button>
      </Container>
      <ExitModal
        plural={playersNumber > 1}
        visible={exitVisible}
        setVisible={setExitVisible}
      />
    </SafeArea>
  );
};

export default ShowRewards;

const Container = styled.View`
  align-items: center;
  flex: 1;
  justify-content: center;
  width: 100%;
`;

const Title = styled(Text)`
  text-align: center;
  color: ${colors.red};
  font-size: 26px;
  margin: 10px 0 30px;
`;

const ContentWrapper = styled.View`
  flex: 1;
  flex-direction: row;
  border-radius: 10px;
  border: 3px solid ${colors.red};
  margin-bottom: 30px;
`;
