import styled from "@emotion/native";
import React, { FC, useRef, useEffect } from "react";
import { Animated } from "react-native";
import Icon from "./Icon";
import colors from "../colors";

export interface IRewardLockedProps {
  height: number;
  lock: boolean;
  width: number;
}

const RewardLocked: FC<IRewardLockedProps> = ({
  children,
  height,
  lock,
  width,
}) => {
  const heightAnim = useRef(new Animated.Value(height)).current;
  const widthAnim = useRef(new Animated.Value(width)).current;

  useEffect(() => {
    if (lock) {
      return;
    }

    Animated.sequence([
      Animated.timing(heightAnim, {
        toValue: height + 2,
        duration: 100,
        useNativeDriver: false,
      }),
      Animated.timing(heightAnim, {
        toValue: height,
        duration: 100,
        useNativeDriver: false,
      }),
    ]).start();
    Animated.sequence([
      Animated.timing(widthAnim, {
        toValue: width + 2,
        duration: 100,
        useNativeDriver: false,
      }),
      Animated.timing(widthAnim, {
        toValue: width,
        duration: 100,
        useNativeDriver: false,
      }),
    ]).start();
  }, [lock]);

  return (
    <Container style={{ elevation: 10, height, width }}>
      <Content style={{ height: heightAnim, width: widthAnim }}>
        <Children>{children}</Children>
        {lock && (
          <>
            <Lock />
            <StyledIcon name="padlock" />
          </>
        )}
      </Content>
    </Container>
  );
};

export default RewardLocked;

const Children = styled.View`
  height: 100%;
  position: absolute;
  width: 100%;
`;

const Container = styled.View`
  align-items: center;
  border-radius: 6px;
  justify-content: center;
  margin: 0 6px;
`;

const Content = styled(Animated.View)`
  align-items: center;
  background-color: ${colors.white};
  border-radius: 6px;
  justify-content: center;
  overflow: hidden;
`;

const Lock = styled.View`
  background-color: ${colors.white};
  height: 100%;
  opacity: 0.9;
  position: absolute;
  width: 100%;
`;

const StyledIcon = styled(Icon)`
  height: 40px;
  width: 40px;
  z-index: 1;
`;
