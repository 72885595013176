import React, { FC } from "react";
import { SvgProps } from "react-native-svg";
import {
  Add,
  Angle,
  Arrow,
  ArrowCircle,
  ArrowSmall,
  Close,
  Delete,
  ExitCircle,
  Group,
  HelpCircle,
  InfoCircle,
  Messages,
  Padlock,
  Solved,
  Warning,
} from "./icons";
import colors from "../colors";

const iconComponents = {
  add: Add,
  angle: Angle,
  arrow: Arrow,
  "arrow-circle": ArrowCircle,
  "arrow-small": ArrowSmall,
  close: Close,
  delete: Delete,
  "exit-circle": ExitCircle,
  group: Group,
  "help-circle": HelpCircle,
  "info-circle": InfoCircle,
  messages: Messages,
  padlock: Padlock,
  solved: Solved,
  warning: Warning,
};

export interface IIconProps extends SvgProps {
  name: string;
}

const Icon: FC<IIconProps> = ({ name, ...props }) => {
  const color = colors.black;
  const IconComponent = iconComponents[name];
  return IconComponent ? (
    <IconComponent {...props} style={[{ color }, props.style]} />
  ) : null;
};

export default Icon;
