import styled from "@emotion/native";
import React, { FC } from "react";
import colors from "../colors";
import Text from "./Text";

interface IBulletPointProps {
  index: number;
}

const BulletPoint: FC<IBulletPointProps> = ({ children, index }) => (
  <Container>
    <NumberContainer>
      <Number bold>{index + 1}</Number>
    </NumberContainer>
    <StyledText>{children}</StyledText>
  </Container>
);

export default BulletPoint;

const Container = styled.View`
  align-items: center;
  flex-direction: row;
  margin-top: 20px;
`;

const Number = styled(Text)`
  color: ${colors.white};
  font-size: 28.5px;
`;

const NumberContainer = styled.View`
  align-items: center;
  background-color: ${colors.turquoise};
  border-radius: 100px;
  height: 52px;
  justify-content: center;
  margin-right: 17px;
  width: 52px;
`;

const StyledText = styled(Text)`
  flex-shrink: 1;
`;
