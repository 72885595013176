import gql from "graphql-tag";

export const APP_MIN_VERSION = gql`
  query AppMinVersion {
    appMinVersion
  }
`;

export const CHECK_CODE_QUERY = gql`
  query CheckCode($code: String!) {
    checkCode(code: $code) {
      open
      valid
    }
  }
`;

export const START_ADVENTURE_QUERY = gql`
  query StartAdventure(
    $age: Ages!
    $numPlayers: Int!
    $language: String!
    $code: String!
  ) {
    startAdventure(
      age: $age
      numPlayers: $numPlayers
      language: $language
      code: $code
    ) {
      adventure
      activities {
        id
        type
        content
        description
      }
    }
  }
`;

export const SUBMIT_RESULTS_MUTATION = gql`
  mutation SubmitResults(
    $code: String!
    $age: String!
    $numPlayers: Int!
    $activities: [String!]!
    $results: [Boolean!]!
  ) {
    submitResults(
      code: $code
      age: $age
      numPlayers: $numPlayers
      activities: $activities
      results: $results
    )
  }
`;
