import styled from "@emotion/native";
import React, { FC, useEffect } from "react";
import { Animated, Easing } from "react-native";
import InsetShadow from "react-native-inset-shadow";
import {
  TextInput as NativeTextInput,
  TextInputProps,
  View,
  StyleProp,
} from "react-native";
import Icon from "./Icon";
import Text from "./Text";
import colors from "../colors";

export type TextInputStatusType = "info" | "success" | "warning" | undefined;

export interface ITextInputProps extends Omit<TextInputProps, "style"> {
  alertMessage?: string;
  onAlertClose: () => void;
  status: TextInputStatusType;
  style?: StyleProp<View>;
}

const TextInput: FC<ITextInputProps> = ({
  alertMessage,
  onAlertClose,
  status,
  style,
  ...props
}) => {
  const color =
    status === "warning"
      ? colors.yellow
      : status === "info"
      ? colors.blue
      : status === "success"
      ? colors.green
      : colors.darkGray;
  const iconColor = status === "success" ? colors.white : colors.black;
  const iconName =
    status === "warning"
      ? "warning"
      : status === "info"
      ? "info-circle"
      : status === "success"
      ? "solved"
      : undefined;

  const translateAnim = new Animated.Value(-55);

  useEffect(() => {
    !!status &&
      Animated.timing(translateAnim, {
        toValue: -60,
        easing: Easing.elastic(10),
        duration: 500,
        useNativeDriver: true,
      }).start();
  }, [status]);

  return (
    <Container style={style}>
      {!!alertMessage && !!status && (
        <AlertMessage
          style={{
            borderColor: color,
            elevation: 2,
            transform: [{ translateY: translateAnim }],
          }}
        >
          <IconWrapper style={{ backgroundColor: color }}>
            <StatusIcon style={{ color: iconColor }} name={iconName} />
            <Angle style={{ borderTopColor: color }} />
          </IconWrapper>
          <Text>{alertMessage}</Text>
          <CloseButton onPress={onAlertClose}>
            <CloseIcon name="close" />
          </CloseButton>
        </AlertMessage>
      )}
      <InsetShadow
        elevation={3}
        shadowOpacity={0.2}
        shadowOffset={3}
        containerStyle={{ borderRadius: 6, height: 44 }}
      >
        <StyledTextInput
          style={{ borderColor: color, borderWidth: !!status ? 2 : 1 }}
          {...props}
        />
      </InsetShadow>
    </Container>
  );
};

export default TextInput;

const AlertMessage = styled(Animated.View)`
  align-items: center;
  background-color: ${colors.white};
  border-radius: 6px;
  border-width: 1px;
  flex-direction: row;
  height: 48px;
  position: absolute;
  top: 0;
  width: 100%;
`;

const CloseButton = styled.TouchableOpacity`
  position: absolute;
  right: 0;
  top: 0;
`;

const CloseIcon = styled(Icon)`
  height: 28px;
  margin: 4px;
  width: 28px;
`;

const Container = styled.View`
  position: relative;
`;

const IconWrapper = styled.View`
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 20px;
  width: 56px;
`;

const StatusIcon = styled(Icon)`
  height: 32px;
  width: 32px;
`;

const StyledTextInput = styled(NativeTextInput)`
  border-radius: 6px;
  color: ${colors.black};
  font-size: 18px;
  padding: 0 12px;
  height: 100%;
  width: 100%;
`;

const Angle = styled.View`
  position: absolute;
  bottom: -9px;
  left: 14px;
  border-top-width: 10px;
  border-right-width: 10px;
  border-bottom-width: 0px;
  border-left-width: 10px;
  border-color: transparent;
`;
