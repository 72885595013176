import * as React from "react";
import Svg, { Rect, G, Path, SvgProps } from "react-native-svg";

function SvgGroup(props: SvgProps) {
  return (
    <Svg viewBox="0 0 64 64" {...props}>
      <G fill="none" fillRule="evenodd" transform="translate(-477 -221)">
        <Path
          d="M532.847 265.197a1.333 1.333 0 01-2.627.452c-1.07-6.207-4.748-9.982-9.353-9.982-3.09 0-5.787 1.693-7.538 4.736 1.684 2.656 2.9 5.943 3.52 9.714a1.334 1.334 0 01-2.631.432c-.608-3.695-1.818-6.818-3.474-9.233a1.164 1.164 0 01-.11-.16c-2.465-3.502-5.885-5.47-9.767-5.47-6.587 0-11.841 5.653-13.351 14.863a1.334 1.334 0 01-2.632-.432c1.292-7.879 5.192-13.637 10.51-15.961A10.658 10.658 0 01490.2 245c0-5.891 4.776-10.667 10.667-10.667 5.89 0 10.666 4.776 10.666 10.667 0 3.89-2.084 7.294-5.195 9.157 1.98.865 3.764 2.203 5.289 3.954 1.219-1.776 2.75-3.135 4.493-4.004a8 8 0 014.747-14.44 8 8 0 014.749 14.438c3.655 1.823 6.318 5.792 7.231 11.092zM520.867 253a5.333 5.333 0 100-10.667 5.333 5.333 0 000 10.667zm-28-8a8 8 0 1016 0 8 8 0 00-16 0z"
          fill="currentColor"
        />
      </G>
    </Svg>
  );
}

export default SvgGroup;
