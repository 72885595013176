import styled from "@emotion/native";
import React, { FC, useEffect } from "react";
import { Animated } from "react-native";
import Icon from "./Icon";
import Text from "./Text";
import colors from "../colors";
import { useBreakpoints } from "../lib/breakpoints";
import { useTranslate } from "../lib/translate";

export interface IErrorMessageProps {
  onClose: () => void;
  visible: boolean;
}

const ErrorMessage: FC<IErrorMessageProps> = ({ onClose, visible }) => {
  const { isDesktop } = useBreakpoints();
  const t = useTranslate();
  const translateAnim = new Animated.Value(64);

  useEffect(() => {
    Animated.timing(translateAnim, {
      duration: 300,
      toValue: visible ? 0 : 64,
      useNativeDriver: false,
    }).start();
  }, [visible]);

  return (
    <Content
      style={{
        transform: [{ translateY: translateAnim }],
      }}
    >
      <WarningIcon name="warning" />
      <StyledText>{t("general.error-message")}</StyledText>
      <CloseButton style={{ right: isDesktop ? 40 : 10 }} onPress={onClose}>
        <CloseIcon name="close" />
      </CloseButton>
    </Content>
  );
};

export default ErrorMessage;

const Content = styled(Animated.View)`
  align-items: center;
  background-color: ${colors.black};
  flex-direction: row;
  justify-content: center;
  height: 64px;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
`;

const CloseButton = styled.TouchableOpacity`
  position: absolute;
`;

const CloseIcon = styled(Icon)`
  color: ${colors.white};
  height: 40px;
  width: 40px;
`;

const StyledText = styled(Text)`
  color: ${colors.white};
  margin-left: 20px;
`;

const WarningIcon = styled(Icon)`
  color: ${colors.yellow};
  height: 32px;
  width: 32px;
`;
