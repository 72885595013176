import styled from "@emotion/native";
import {
  Platform,
  ScrollView,
  StyleProp,
  TouchableOpacity,
  View,
} from "react-native";
import React, { FC, useEffect, useRef, useState } from "react";
import { useBreakpoints } from "../lib/breakpoints";
import colors from "../colors";
import Icon from "./Icon";
import Text from "./Text";

export interface IScrollableTextProps {
  text: string;
  style?: StyleProp<View>;
  scrollStep?: number;
}

const ScrollableText: FC<IScrollableTextProps> = ({
  text,
  style,
  scrollStep = 20,
}) => {
  const { isTall } = useBreakpoints();
  const [containerHeight, setContainerHeight] = useState(0);
  const [textHeight, setTextHeight] = useState(0);
  const [hasScroll, setHasScroll] = useState(false);
  const [canScrollUp, setCanScrollUp] = useState(false);
  const [canScrollDown, setCanScrollDown] = useState(true);
  const [scroll, setScroll] = useState(0);
  const scrollableRef = useRef<ScrollView>(null);

  useEffect(() => {
    setHasScroll(textHeight > containerHeight);
  }, [containerHeight, textHeight]);

  useEffect(() => {
    setCanScrollUp(scroll > 0);
    setCanScrollDown(textHeight - containerHeight + 20 - scroll > 0);
  }, [scroll]);

  return (
    <Container
      onLayout={(e) => setContainerHeight(e.nativeEvent.layout.height)}
      style={[style, Platform.OS !== "web" && { alignItems: "center" }]}
    >
      <StyledScrollView
        ref={scrollableRef}
        onScroll={(e) => setScroll(e.nativeEvent.contentOffset.y)}
        style={Platform.OS === "web" && { overflowY: "hidden" }}
      >
        <Text
          style={{ lineHeight: scrollStep }}
          onLayout={(e) => setTextHeight(e.nativeEvent.layout.height)}
        >
          {text}
        </Text>
      </StyledScrollView>
      {hasScroll && (
        <View
          style={{
            transform: isTall ? undefined : [{ translateY: -10 }],
          }}
        >
          <TouchableOpacity
            style={{
              width: isTall ? 40 : 30,
              height: isTall ? 40 : 30,
            }}
            activeOpacity={canScrollUp ? 0.3 : 1}
            onPress={() =>
              canScrollUp &&
              scrollableRef.current?.scrollTo({
                y: scroll - scrollStep,
                animated: true,
              })
            }
          >
            <UpArrow
              name="angle"
              style={{
                color: canScrollUp ? colors.black : colors.lightGray,
                width: isTall ? 40 : 30,
                height: isTall ? 40 : 30,
              }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              width: isTall ? 40 : 30,
              height: isTall ? 40 : 30,
            }}
            activeOpacity={canScrollDown ? 0.3 : 1}
            onPress={() =>
              canScrollDown &&
              scrollableRef.current?.scrollTo({
                y: scroll + scrollStep,
                animated: true,
              })
            }
          >
            <DownArrow
              name="angle"
              style={{
                color: canScrollDown ? colors.black : colors.lightGray,
                width: isTall ? 40 : 30,
                height: isTall ? 40 : 30,
              }}
            />
          </TouchableOpacity>
        </View>
      )}
    </Container>
  );
};

export default ScrollableText;

const Container = styled.View`
  padding: 10px;
  flex-direction: row;
  overflow: hidden;
`;

const StyledScrollView = styled.ScrollView`
  margin-right: 10px;
`;

const UpArrow = styled(Icon)`
  transform: rotate(180deg);
`;

const DownArrow = styled(Icon)``;
