import styled from "@emotion/native";
import React, { FC } from "react";
import { TouchableOpacity } from "react-native";
import Icon from "./Icon";
import colors from "../colors";
import { useBreakpoints } from "../lib/breakpoints";

interface IHeaderButtonProps {
  iconName: string;
  onPress: () => void;
}

export interface IHeaderProps {
  leftButton?: IHeaderButtonProps;
  rightButton?: IHeaderButtonProps;
}

const Header: FC<IHeaderProps> = ({ leftButton, rightButton }) => {
  const { isDesktop } = useBreakpoints();

  return (
    <>
      {leftButton && (
        <Container
          style={{ left: isDesktop ? 50 : 20, top: isDesktop ? 40 : 20 }}
        >
          <TouchableOpacity onPress={leftButton.onPress}>
            <StyledIcon name={leftButton.iconName} />
          </TouchableOpacity>
        </Container>
      )}
      {rightButton && (
        <Container
          style={{ right: isDesktop ? 50 : 20, top: isDesktop ? 40 : 20 }}
        >
          <TouchableOpacity onPress={rightButton.onPress}>
            <StyledIcon name={rightButton.iconName} />
          </TouchableOpacity>
        </Container>
      )}
    </>
  );
};

export default Header;

const Container = styled.View`
  position: absolute;
  z-index: 1;
`;

const StyledIcon = styled(Icon)`
  width: 64px;
  height: 64px;
  color: ${colors.red};
`;
