import styled from "@emotion/native";
import React, { FC, useEffect, useState } from "react";
import { Platform } from "react-native";
import colors from "../../colors";
import { useBreakpoints } from "../../lib/breakpoints";
import { IMatchActivityContent } from "../../types";
import Text from "../Text";

export interface IMatchActivityProps {
  content: IMatchActivityContent;
  onCompleteChange: (complete: boolean, success: boolean) => void;
}

interface IOption {
  x: number;
  y: number;
  width: number;
  height: number;
}

const pairColors = [
  colors.red,
  colors.albergine,
  colors.green,
  colors.turquoise,
];

const MatchActivity: FC<IMatchActivityProps> = ({
  content,
  onCompleteChange,
}) => {
  const { isDesktop } = useBreakpoints();
  const { options } = content;
  const [optionLayouts, setOptionsLayouts] = useState<Array<IOption>>(
    Array.from(new Array(options.length))
  );
  const [selected, setSelected] = useState(-1);
  const [pairs, setPairs] = useState<Array<[number, number]>>([]);
  const rows =
    options.length === 6
      ? [
          [0, 1, 2],
          [3, 4, 5],
        ]
      : [
          [0, 1, 2, 3],
          [4, 5, 6, 7],
        ];

  useEffect(() => {
    const success = pairs.every((pair) => options[pair[0]].pair === pair[1]);
    onCompleteChange(pairs.length === options.length / 2, success);
  }, [pairs]);

  const connected = pairs.flat();

  const onOptionPress = (index: number) => {
    if (selected < 0) {
      if (connected.includes(index)) {
        setPairs(pairs.filter((pair) => !pair.includes(index)));
      } else {
        setSelected(index);
      }
    } else if (selected === index) {
      setSelected(-1);
    } else if (
      rows.some((row) => row.includes(selected) && row.includes(index))
    ) {
      if (connected.includes(index)) {
        setPairs(pairs.filter((pair) => !pair.includes(index)));
      }
      setSelected(index);
    } else {
      setPairs([
        ...pairs.filter((pair) => !pair.includes(index)),
        [selected, index],
      ]);
      setSelected(-1);
    }
  };

  const getPairIndex = (index: number) => {
    const pair = pairs.find((pair) => pair.includes(index)) || [0, 0];
    return Math.min(pair[0], pair[1]);
  };

  const getLineStyle = (option1: number, option2: number) => {
    const from = Math.min(option1, option2);
    const to = Math.max(option1, option2);
    const fromLayout = optionLayouts[from];
    const toLayout = optionLayouts[to];

    if (!fromLayout || !toLayout) {
      return {};
    }

    const fromX = fromLayout.x + fromLayout.width / 2;
    const toX = toLayout.x + toLayout.width / 2;
    const diffX = toX - fromX;
    const length = Math.sqrt(diffX * diffX + 2500);

    const angle = (Math.atan2(50, diffX) * 180) / Math.PI;

    return {
      borderColor: pairColors[from],
      width: length,
      bottom: -25,
      left: (toX + fromX - length) / 2,
      transform: [{ translateY: -25 }, { rotate: `${angle}deg` }],
    };
  };

  return (
    <Container>
      {rows.map((row, i) => (
        <Row
          key={`row-${i}`}
          style={{ paddingHorizontal: isDesktop ? 15 : 10 }}
        >
          {i === 0 &&
            pairs.map(([option1, option2]) => (
              <Line
                key={`line-${option1}-${option2}`}
                style={getLineStyle(option1, option2)}
              />
            ))}
          {row.map((index, j) => (
            <Option
              key={index}
              onLayout={(e: { nativeEvent: { layout: IOption } }) => {
                const newOptions: IOption[] = optionLayouts;
                optionLayouts[index] = e.nativeEvent.layout;
                setOptionsLayouts(newOptions);
              }}
              style={
                connected.includes(index) || selected === index
                  ? {
                      borderColor:
                        selected === index
                          ? colors.turquoise
                          : pairColors[getPairIndex(index)],
                      borderWidth: 4,
                      marginHorizontal: isDesktop ? 13 : 8,
                      marginVertical: -2,
                    }
                  : {
                      borderColor: colors.lightGray,
                      borderWidth: 2,
                      marginHorizontal: isDesktop ? 15 : 10,
                      marginVertical: 0,
                    }
              }
              onPress={() => onOptionPress(index)}
            >
              {options[index] && options[index].text && (
                <StyledText>{options[index].text}</StyledText>
              )}
              {options[index] && options[index].image && (
                <Image source={{ uri: options[index].image }} />
              )}
              <Circle
                style={
                  connected.includes(index)
                    ? {
                        backgroundColor: pairColors[getPairIndex(index)],
                        [i === 0 ? "bottom" : "top"]: -15,
                        transform: [
                          { translateX: Platform.OS === "web" ? -15 : 5 },
                        ],
                      }
                    : {
                        backgroundColor: colors.white,
                        borderWidth: 2,
                        borderColor: colors.darkGray,
                        [i === 0 ? "bottom" : "top"]: -15,
                        transform: [
                          { translateX: Platform.OS === "web" ? -15 : 5 },
                        ],
                      }
                }
              >
                {connected && (
                  <CircleText bold>{getPairIndex(index) + 1}</CircleText>
                )}
              </Circle>
            </Option>
          ))}
        </Row>
      ))}
    </Container>
  );
};

export default MatchActivity;

const Container = styled.View`
  flex: 1;
  align-items: center;
  padding: 35px 0;
`;

const Row = styled.View`
  flex-direction: row;
  padding-vertical: 25px;
  flex: 1;
  width: 100%;
`;

const Option = styled.TouchableOpacity`
  align-items: center;
  flex: 1;
  border-radius: 10px;
  justify-content: center;
  padding: 20px;
`;

const Image = styled.Image`
  width: 100%;
  height: 100%;
  resize-mode: contain;
`;

const StyledText = styled(Text)`
  text-align: center;
`;

const Circle = styled.View`
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
`;

const CircleText = styled(Text)`
  color: white;
`;

const Line = styled.View`
  position: absolute;
  border-top-width: 2px;
`;
