import { useEffect, useState } from "react";
import { Dimensions } from "react-native";

export const desktopBreakpoint = 1280; //px
export const tallBreakpoint = 800; //px

interface IUseBreakpointsReturn {
  isDesktop: boolean;
  isTall: boolean;
}

const initialScreen = Dimensions.get("screen");

export const useBreakpoints = (): IUseBreakpointsReturn => {
  const [screen, setScreen] = useState(initialScreen);
  const onChange = ({ screen }) => {
    setScreen(screen);
  };

  useEffect(() => {
    Dimensions.addEventListener("change", onChange);
    return () => {
      Dimensions.removeEventListener("change", onChange);
    };
  });

  const isDesktop = screen.width >= desktopBreakpoint;
  const isTall = screen.height >= tallBreakpoint;

  return { isDesktop, isTall };
};
