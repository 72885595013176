import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

function SvgDelete(props: SvgProps) {
  return (
    <Svg viewBox="0 0 40 40" {...props}>
      <Path
        fill="currentColor"
        fillRule="evenodd"
        d="M25.833 8.333H32.5a.833.833 0 010 1.667h-.833v23.333a3.334 3.334 0 01-3.334 3.334H11.667a3.334 3.334 0 01-3.334-3.334V10H7.5a.833.833 0 010-1.667h6.667V7.5a4.167 4.167 0 014.166-4.167h3.334c2.3 0 4.166 1.866 4.166 4.167v.833zm-1.666 0V7.5a2.5 2.5 0 00-2.5-2.5h-3.334a2.5 2.5 0 00-2.5 2.5v.833h8.334zM30 10H10v23.333c0 .92.746 1.667 1.667 1.667h16.666c.92 0 1.667-.746 1.667-1.667V10zm-4.167 20a.833.833 0 01-1.666 0V15a.833.833 0 011.666 0v15zm-5 0a.833.833 0 01-1.666 0V15a.833.833 0 011.666 0v15zm-5 0a.833.833 0 01-1.666 0V15a.833.833 0 011.666 0v15z"
      />
    </Svg>
  );
}

export default SvgDelete;
