import styled from "@emotion/native";
import React, { FC } from "react";
import colors from "../colors";
import Icon from "./Icon";

export interface IRightWrongDrawerProps {
  wrongSelected: boolean;
  onChange: (wrongSelected: boolean) => void;
}

const RightWrongDrawer: FC<IRightWrongDrawerProps> = ({
  wrongSelected,
  onChange,
}) => {
  return (
    <Drawer>
      <DrawerButton
        onPress={() => onChange(false)}
        style={{
          backgroundColor: wrongSelected ? colors.white : colors.green,
        }}
      >
        <DrawerIcon
          name="solved"
          style={{
            color: wrongSelected ? colors.green : colors.white,
          }}
        />
      </DrawerButton>
      <DrawerButton
        onPress={() => onChange(true)}
        style={{
          backgroundColor: wrongSelected ? colors.red : colors.white,
        }}
      >
        <DrawerIcon
          name="close"
          style={{
            color: wrongSelected ? colors.white : colors.red,
          }}
        />
      </DrawerButton>
    </Drawer>
  );
};

export default RightWrongDrawer;

const Drawer = styled.View`
  height: 80px;
  background-color: ${colors.black};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const DrawerButton = styled.TouchableOpacity`
  width: 64px;
  height: 64px;
  border-radius: 10px;
  margin: 0 5px;
`;

const DrawerIcon = styled(Icon)`
  width: 64px;
  height: 64px;
`;
