import styled from "@emotion/native";
import React, { FC } from "react";
import ModalWeb from "modal-react-native-web";
import { Modal, Platform } from "react-native";
import colors from "../colors";
import Button from "./Button";
import Text from "./Text";

interface IModalContentProps {
  title: string;
  message: string;
  okText: string;
  onOk: () => void;
  cancelText?: string;
  onCancel?: () => void;
}

const ModalContent: FC<IModalContentProps> = ({
  title,
  message,
  okText,
  onOk,
  cancelText,
  onCancel,
}) => (
  <Overlay>
    <Content>
      <Title>{title}</Title>
      <Message>{message}</Message>
      <Buttons>
        <StyledButton onPress={onOk}>{okText}</StyledButton>
        {cancelText && onCancel && (
          <StyledButton onPress={onCancel} tertiary>
            {cancelText}
          </StyledButton>
        )}
      </Buttons>
    </Content>
  </Overlay>
);

export interface IDialogModalProps extends IModalContentProps {
  visible: boolean;
}

const DialogModal: FC<IDialogModalProps> = ({ visible, ...props }) =>
  Platform.OS === "web" ? (
    <ModalWeb animationType="fade" transparent={true} visible={visible}>
      <ModalContent {...props} />
    </ModalWeb>
  ) : (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      statusBarTranslucent={true}
    >
      <ModalContent {...props} />
    </Modal>
  );

export default DialogModal;

const Overlay = styled.View`
  background-color: rgba(0, 0, 0, 0.7);
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Content = styled.View`
  background-color: ${colors.white};
  border-radius: 10px;
  padding: 24px;
`;

const Title = styled(Text)`
  font-size: 26px;
  margin-bottom: 10px;
`;

const Message = styled(Text)`
  margin-bottom: 20px;
`;

const Buttons = styled.View`
  flex-direction: row;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;
