import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

function SvgHelpCircle(props: SvgProps) {
  return (
    <Svg viewBox="0 0 64 64" {...props}>
      <Path
        fill="currentColor"
        fillRule="evenodd"
        d="M32 43.333a2 2 0 11-.001 4.001A2 2 0 0132 43.333zM32 56c13.255 0 24-10.745 24-24S45.255 8 32 8 8 18.745 8 32s10.745 24 24 24zm0-50.667c14.728 0 26.667 11.94 26.667 26.667 0 14.728-11.94 26.667-26.667 26.667-14.728 0-26.667-11.94-26.667-26.667C5.333 17.272 17.273 5.333 32 5.333zm.006 11.92c3.26.015 5.8 1.937 6.909 4.825 1.04 2.712.607 5.79-1.262 7.691a181.102 181.102 0 01-3.385 3.372c-.6.59-.936 1.396-.935 2.239v2.577a1.333 1.333 0 01-2.666 0v-2.574a5.8 5.8 0 011.737-4.15c1.097-1.065 2.18-2.14 3.345-3.331 1.086-1.105 1.364-3.076.676-4.87-.73-1.9-2.319-3.102-4.431-3.112-2.121-.01-3.537.705-4.478 1.909a5.19 5.19 0 00-.747 1.323c-.1.26-.15.447-.165.523a1.334 1.334 0 01-2.621-.49c.042-.227.135-.57.297-.99a7.82 7.82 0 011.134-2.007c1.44-1.843 3.63-2.948 6.592-2.935z"
      />
    </Svg>
  );
}

export default SvgHelpCircle;
