import { useLazyQuery } from "@apollo/client";
import styled from "@emotion/native";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { FC, useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import colors from "../colors";
import {
  Button,
  ErrorMessage,
  Header,
  SafeArea,
  Text,
  TextInput,
  TextInputStatusType,
} from "../components";
import { useBreakpoints } from "../lib/breakpoints";
import { useTranslate } from "../lib/translate";
import { CHECK_CODE_QUERY } from "../queries";

const EnterKeyScreen: FC = () => {
  const t = useTranslate();
  const { isDesktop } = useBreakpoints();
  const navigation = useNavigation();
  const [error, setError] = useState<boolean>(false);
  const [key, setKey] = useState<string>("");
  const [status, setStatus] = useState<TextInputStatusType>(undefined);
  const [checkCode, { loading, data, error: apolloError }] = useLazyQuery(
    CHECK_CODE_QUERY,
    {
      fetchPolicy: "network-only",
    }
  );

  const onChangeText = (value: string) => {
    setKey(value);
    setStatus(undefined);
  };

  const onSubmit = async () => {
    setStatus(undefined);
    /* Development only
    if (
      key.trim().toUpperCase() === "TESTINITIAL" ||
      key.trim().toUpperCase() === "TESTFINAL"
    ) {
      navigation.navigate("EnterAge", { key: key.trim() });
      return;
    }
    */
    checkCode({ variables: { code: key.trim() } });
  };

  useEffect(() => setError(!!apolloError), [apolloError]);

  useEffect(() => {
    if (!loading && data && data.checkCode) {
      if (data.checkCode.open) {
        navigation.navigate("EnterAge", { key: key.trim() });
      } else if (data.checkCode.valid) {
        setStatus("info");
      } else {
        setStatus("warning");
      }
    }
  }, [loading]);

  // Reset key value every time the user reach this page
  useFocusEffect(useCallback(() => setKey(""), []));

  return (
    <SafeArea>
      <Header
        leftButton={{
          iconName: "arrow-circle",
          onPress: () => {
            setStatus(undefined);
            navigation.goBack();
          },
        }}
        rightButton={{
          iconName: "help-circle",
          onPress: () => {
            setStatus(undefined);
            navigation.navigate("Help");
          },
        }}
      />
      <Container>
        <KeyboardAvoiding behavior="padding">
          <View
            style={{
              height: isDesktop ? 380 : 229,
              width: isDesktop ? 380 : 229,
            }}
          >
            <DiodiKeyImage source={require("../assets/images/diodi-key.png")} />
          </View>
          <Title>{t("enter-key.text")}</Title>
          <StyledTextInput
            alertMessage={
              status === "warning"
                ? t("enter-key.error-invalid")
                : status === "info"
                ? t("enter-key.error-closed")
                : undefined
            }
            autoCapitalize="characters"
            onAlertClose={() => setStatus(undefined)}
            onChangeText={(text: string) => onChangeText?.(text.toUpperCase())}
            placeholder={t("enter-key.placeholder")}
            status={status}
            style={{ width: isDesktop ? 579 : 479 }}
            value={key}
          />
          <Button
            iconName="arrow-small"
            onPress={() => onSubmit()}
            disabled={loading}
          >
            {t("general.continue-button")}
          </Button>
        </KeyboardAvoiding>
        <ErrorMessage visible={error} onClose={() => setError(false)} />
      </Container>
    </SafeArea>
  );
};

export default EnterKeyScreen;

const Container = styled.View`
  align-items: center;
  flex: 1;
  justify-content: center;
  overflow: hidden;
`;

const DiodiKeyImage = styled.Image`
  flex: 1;
  resize-mode: contain;
  width: auto;
`;

const KeyboardAvoiding = styled.KeyboardAvoidingView`
  align-items: center;
`;

const StyledTextInput = styled(TextInput)`
  margin: 30px 0;
`;

const Title = styled(Text)`
  color: ${colors.red};
  font-size: 26px;
  margin-top: 10px;
`;
