import styled from "@emotion/native";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import React, { FC, useEffect, useState, useRef } from "react";
import { Animated, Easing } from "react-native";
import colors from "../colors";
import { ExitModal, RewardLocked, SafeArea, Text } from "../components";
import { cards, diplomas } from "../config";
import { useBreakpoints } from "../lib/breakpoints";
import { useTranslate } from "../lib/translate";
import { IReward, StackParamList } from "../types";

const UnlockRewardsScreen: FC = () => {
  const t = useTranslate();
  const { isDesktop } = useBreakpoints();
  const navigation = useNavigation();
  const { params } = useRoute<RouteProp<StackParamList, "UnlockRewards">>();
  const { activitiesNumber, adventure, success, key, playersNumber } = params;
  const progressAnim = useRef(new Animated.Value(0));

  const [color, setColor] = useState<string>(colors.red);
  const [exitVisible, setExitVisible] = useState<boolean>(false);
  const [unlock, setUnlock] = useState<number>(0);

  const width = progressAnim.current.interpolate({
    inputRange: [0, 1],
    outputRange: ["0%", "100%"],
    extrapolate: "clamp",
  });

  const onNextStep = (unlockNumber: number) =>
    navigation.navigate("SelectRewards", {
      unlockNumber,
      adventure,
      playersNumber,
      key,
    });
  let timeout: any;

  useEffect(() => {
    const maxUnlock = (adventure === "initial" ? cards : diplomas).length;
    const minUnlock = 1;
    let unlockNumber = 0;

    const listener = progressAnim.current.addListener(({ value }) => {
      value > 0.5 && setColor(colors.white);
      unlockNumber = Math.floor(value * maxUnlock);
      setUnlock(unlockNumber);
    });

    Animated.timing(progressAnim.current, {
      duration: 3000,
      easing: Easing.linear,
      toValue: Math.max(success / activitiesNumber, minUnlock / maxUnlock),
      useNativeDriver: false,
    }).start(
      () => (
        progressAnim.current.removeListener(listener),
        (timeout = setTimeout(() => onNextStep(unlockNumber), 3000))
      )
    );
  }, []);

  //Stop the animation when the component is unmounted
  useEffect(
    () => () => {
      clearTimeout(timeout);
      progressAnim.current.removeAllListeners();
    },
    []
  );

  return (
    <SafeArea>
      <Container>
        <Title>{t("unlock-rewards.title")}</Title>
        <TextContent>
          <Text>
            {t(
              `unlock-rewards.text-success.${
                playersNumber > 1 ? "plural" : "singular"
              }`
            )}
          </Text>
          <Text bold>
            {t(
              `unlock-rewards.text-question.${
                success === 1 ? "singular" : "plural"
              }`,
              [success + ""]
            )}
          </Text>
        </TextContent>
        <Progress
          style={{
            width:
              adventure === "initial"
                ? isDesktop
                  ? 780
                  : 603
                : isDesktop
                ? 380
                : 353,
          }}
        >
          <ProgressBar style={{ width }} />
          <ProgressNumber bold style={{ color }}>
            {success}
          </ProgressNumber>
        </Progress>
        <Rewards>
          {adventure === "initial"
            ? cards.map((card: IReward, index: number) => (
                <RewardLocked
                  height={isDesktop ? 90 : 72}
                  lock={unlock < index + 1}
                  key={index}
                  width={isDesktop ? 65 : 52}
                >
                  <Card style={{ backgroundColor: card.color }} />
                </RewardLocked>
              ))
            : diplomas.map((diploma: IReward, index: number) => (
                <RewardLocked
                  height={isDesktop ? 90 : 72}
                  lock={unlock < index + 1}
                  key={index}
                  width={isDesktop ? 125 : 100}
                >
                  <Diploma source={diploma.thumbnail} />
                </RewardLocked>
              ))}
        </Rewards>
        <Text>
          {t(
            `unlock-rewards.text-unlock.${
              playersNumber === 1 ? "singular" : "plural"
            }`
          )}
          {t(
            `unlock-rewards.text-item.${adventure}.${
              unlock === 1 ? "singular" : "plural"
            }`,
            [unlock + ""]
          )}
        </Text>
      </Container>
      <ExitModal
        plural={playersNumber > 1}
        visible={exitVisible}
        setVisible={setExitVisible}
      />
    </SafeArea>
  );
};

export default UnlockRewardsScreen;

const Card = styled.View`
  border-radius: 6px;
  border: 2px solid ${colors.white};
  flex: 1;
`;

const Container = styled.View`
  align-items: center;
  flex: 1;
  justify-content: center;
`;

const Diploma = styled.Image`
  flex: 1;
  resize-mode: contain;
  width: auto;
`;

const Progress = styled.View`
  border: 1px solid ${colors.gray};
  border-radius: 10px;
  justify-content: center;
  height: 60px;
  margin: 20px 0;
  padding: 6px;
`;

const ProgressNumber = styled(Text)`
  align-self: center;
  font-size: 30px;
  position: absolute;
`;

const ProgressBar = styled(Animated.View)`
  background-color: ${colors.red};
  border-radius: 5px;
  height: 100%;
`;

const Rewards = styled.View`
  flex-direction: row;
  margin-bottom: 20px;
`;

const TextContent = styled.View`
  flex-direction: row;
`;

const Title = styled(Text)`
  color: ${colors.red};
  font-size: 26px;
  margin-bottom: 20px;
`;
