import styled from "@emotion/native";
import React, { FC } from "react";
import { TouchableOpacityProps } from "react-native";
import colors from "../colors";
import Text from "./Text";
import Icon from "./Icon";

export interface IButtonProps extends TouchableOpacityProps {
  iconName?: string;
  secondary?: boolean;
  tertiary?: boolean;
}

const Button: FC<IButtonProps> = ({
  iconName,
  secondary,
  tertiary,
  children,
  ...props
}) => {
  const backgroundColor = props.disabled
    ? colors.lightGray
    : secondary
    ? colors.white
    : tertiary
    ? colors.darkGray
    : colors.red;

  const color = secondary ? colors.black : colors.white;

  return (
    <Touchable {...props} style={[{ backgroundColor }, props.style]}>
      <Content>
        {typeof children === "string" ? (
          <Text style={{ color }}>{children}</Text>
        ) : (
          children
        )}
        {iconName && <StyledIcon name={iconName} style={{ color }} />}
      </Content>
    </Touchable>
  );
};

export default Button;

const Content = styled.View`
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const Touchable = styled.TouchableOpacity`
  border-radius: 6px;
  padding: 10px 20px;
`;

const StyledIcon = styled(Icon)`
  height: 26px;
  width: 26px;
  margin: -1px 0 -1px 10px;
`;
