import styled from "@emotion/native";
import React, { FC, useState, ReactElement } from "react";
import { View } from "react-native";
import Icon from "./Icon";
import Text from "./Text";
import colors from "../colors";
import { IPlayer } from "../types";
import { characters } from "../config";
import { useTranslate } from "../lib/translate";
import { useBreakpoints } from "../lib/breakpoints";

interface IChildrenProps {
  children: ReactElement;
  getPlayer: () => IPlayer;
  notAllowed: boolean;
}

export interface IPlayerSelectorProps {
  block: boolean;
  children: (props: IChildrenProps) => ReactElement;
  onAdd?: () => void;
  onDelete?: () => void;
  player?: IPlayer;
  players: IPlayer[];
}

const PlayerSelector: FC<IPlayerSelectorProps> = ({
  block,
  children,
  onAdd,
  onDelete,
  player,
  players,
}) => {
  const t = useTranslate();
  const { isDesktop } = useBreakpoints();
  const [characterIndex, setCharacterIndex] = useState<number>(0);

  const size = isDesktop ? 336 : 185;

  const notAllowed =
    players.filter(
      (player: IPlayer) => player.avatar === characters[characterIndex].avatar
    ).length > 0;

  const onLeft = () =>
    characterIndex > 0
      ? setCharacterIndex(characterIndex - 1)
      : setCharacterIndex(characters.length - 1);
  const onRight = () =>
    characterIndex < characters.length - 1
      ? setCharacterIndex(characterIndex + 1)
      : setCharacterIndex(0);

  return children({
    children: block ? (
      <EmptyContainer onPress={onAdd} style={{ height: size, width: size }}>
        <AddIcon name="add" />
      </EmptyContainer>
    ) : (
      <>
        <View>
          <AvatarContainer
            style={{
              borderColor: (player || characters[characterIndex]).color,
              height: size,
              width: size,
            }}
          >
            <AvatarImage
              source={
                player
                  ? player.image
                  : notAllowed
                  ? characters[characterIndex].imageGray
                  : characters[characterIndex].image
              }
            />
          </AvatarContainer>
          {onDelete && (
            <ButtonDelete onPress={onDelete} style={{ elevation: 3 }}>
              <DeleteIcon name="delete" />
            </ButtonDelete>
          )}
          <ButtonGroup>
            {!!player ? (
              <TickContainer style={{ backgroundColor: player.color }}>
                <TickIcon name="solved" />
              </TickContainer>
            ) : (
              <>
                <ButtonLeft onPress={onLeft} style={{ elevation: 3 }}>
                  <AngleIcon name="angle" />
                </ButtonLeft>
                <ButtonRight onPress={onRight} style={{ elevation: 3 }}>
                  <AngleIcon name="angle" />
                </ButtonRight>
              </>
            )}
          </ButtonGroup>
        </View>
        <AvatarName
          style={{ color: (player || characters[characterIndex]).color }}
        >
          {t(
            `general.characters.${(player || characters[characterIndex]).name}`
          )}
        </AvatarName>
      </>
    ),
    getPlayer: () => player || characters[characterIndex],
    notAllowed,
  });
};

export default PlayerSelector;

const AddIcon = styled(Icon)`
  height: 64px;
  justify-self: center;
  width: 64px;
`;

const AngleIcon = styled(Icon)`
  height: 30px;
  width: 30px;
`;

const ControlButton = styled.TouchableOpacity`
  align-items: center;
  background-color: ${colors.white};
  border-radius: 100px;
  height: 44px;
  justify-content: center;
  position: absolute;
  width: 44px;
`;

const AvatarContainer = styled.View`
  border: 3px solid transparent;
  border-radius: 500px;
  overflow: hidden;
`;

const AvatarImage = styled.Image`
  width: 100%;
  height: 100%;
  resize-mode: cover;
`;

const AvatarName = styled(Text)`
  align-self: center;
  font-size: 26px;
  margin: 10px 0;
`;

const ButtonDelete = styled(ControlButton)`
  align-self: flex-end;
  box-shadow: 0px 2px 4px ${colors.gray};
  z-index: 1;
`;

const ButtonGroup = styled.View`
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
`;

const ButtonLeft = styled(ControlButton)`
  box-shadow: 2px 0px 4px ${colors.gray};
  left: -22px;
  transform: rotate(90deg);
`;

const ButtonRight = styled(ControlButton)`
  box-shadow: -2px 0px 4px ${colors.gray};
  right: -22px;
  transform: rotate(-90deg);
`;

const DeleteIcon = styled(Icon)`
  color: ${colors.red};
  height: 30px;
  width: 30px;
`;

const EmptyContainer = styled.TouchableOpacity`
  align-items: center;
  border: 1px dashed ${colors.black};
  border-radius: 500px;
  justify-content: center;
`;

const TickContainer = styled.View`
  align-items: center;
  align-self: center;
  border-radius: 100px;
  color: ${colors.white};
  height: 60px;
  justify-content: center;
  width: 60px;
`;

const TickIcon = styled(Icon)`
  color: ${colors.white};
  height: 40px;
  width: 40px;
`;
