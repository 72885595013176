import styled from "@emotion/native";
import React, { FC, useEffect, useRef, useState } from "react";
import { Animated, TouchableOpacity } from "react-native";
import Icon from "./Icon";
import colors from "../colors";
import { IReward } from "../types";

export interface IRewardCarouselProps {
  slides: number;
  height: number;
  width: number;
  rewards: IReward[];
  setReward: (index: number) => void;
}

const RewardCarousel: FC<IRewardCarouselProps> = ({
  height,
  width,
  rewards,
  setReward,
  slides,
}) => {
  const sideSlides = (slides - 1) / 2;
  const [rewardIndex, setRewardIndex] = useState<number>(sideSlides);

  const positionAnim = useRef(new Animated.Value(0)).current;

  const onLeft = () =>
    rewardIndex > sideSlides && setRewardIndex(rewardIndex - 1);
  const onReward = (index: number) => (
    setRewardIndex(index), setReward(index - sideSlides)
  );
  const onRight = () =>
    rewardIndex < rewards.length + sideSlides - 1 &&
    setRewardIndex(rewardIndex + 1);

  useEffect(() => {
    Animated.timing(positionAnim, {
      toValue: -(rewardIndex - sideSlides) * width * 0.5,
      duration: 500,
      useNativeDriver: false,
    }).start();
  }, [rewardIndex]);

  return (
    <Container>
      <ContentWrapper
        style={{
          height,
          width:
            width + ((sideSlides === 1 ? 0 : width * 0.75) + width * 0.5) * 2,
        }}
      >
        <Content style={{ left: positionAnim }}>
          {[
            ...Array(sideSlides).fill(undefined),
            ...rewards,
            ...Array(sideSlides).fill(undefined),
          ].map((item: IReward | undefined, index: number) => {
            const scale =
              index === rewardIndex
                ? 1
                : index < rewardIndex - 1 ||
                  index > rewardIndex + 1 ||
                  sideSlides === 1
                ? 0.5
                : 0.75;

            return (
              <TouchableOpacity
                key={index}
                onPress={item ? () => onReward(index) : undefined}
              >
                <RewardImage
                  key={`${index}-${scale}-${rewardIndex}`} // It's necessary to force re-render on production
                  source={item?.source}
                  style={{
                    height: height * scale,
                    width: width * scale,
                  }}
                />
              </TouchableOpacity>
            );
          })}
        </Content>
      </ContentWrapper>
      {rewards.length > 1 && (
        <>
          <ButtonLeft onPress={onLeft} style={{ elevation: 3 }}>
            <AngleIcon name="angle" />
          </ButtonLeft>
          <ButtonRight onPress={onRight} style={{ elevation: 3 }}>
            <AngleIcon name="angle" />
          </ButtonRight>
        </>
      )}
    </Container>
  );
};

export default RewardCarousel;

const AngleIcon = styled(Icon)`
  height: 30px;
  width: 30px;
`;

const Container = styled.View`
  justify-content: center;
`;

const ContentWrapper = styled.View`
  flex-direction: row;
  overflow: hidden;
`;

const Content = styled(Animated.View)`
  align-items: center;
  flex-direction: row;
  height: 100%;
  position: absolute;
  width: 100%;
`;

const ControlButton = styled.TouchableOpacity`
  align-items: center;
  background-color: ${colors.white};
  border-radius: 100px;
  height: 44px;
  justify-content: center;
  position: absolute;
  width: 44px;
`;

const ButtonLeft = styled(ControlButton)`
  box-shadow: 2px 0px 4px ${colors.gray};
  left: -22px;
  transform: rotate(90deg);
`;

const ButtonRight = styled(ControlButton)`
  box-shadow: -2px 0px 4px ${colors.gray};
  right: -22px;
  transform: rotate(-90deg);
`;

const RewardImage = styled(Animated.Image)`
  resize-mode: cover;
`;
