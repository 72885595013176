import styled from "@emotion/native";
import React, { FC, useEffect, useRef, useState } from "react";

const MIN_SPEED = -3;
const MAX_SPEED = 3;
const MIN_TURN_TIME = 3000;
const MAX_TURN_TIME = 8000;

export interface ICatchItemProps {
  image: string;
  canvasSize: [number, number];
  onPress: () => void;
}

const getRandom = (min: number, max: number) =>
  Math.random() * (max - min) + min;

const CatchItem: FC<ICatchItemProps> = ({ image, canvasSize, onPress }) => {
  const [position, setPosition] = useState<[number, number]>([0, 0]);
  const posRef = useRef<[number, number]>([0, 0]);
  const velocityRef = useRef<[number, number]>([0, 0]);
  const canvasSizeRef = useRef<[number, number]>([0, 0]);

  const updateSpeed = () => {
    velocityRef.current = [
      getRandom(MIN_SPEED, MAX_SPEED),
      getRandom(MIN_SPEED, MAX_SPEED),
    ];
    const nextSpeedChange = getRandom(MIN_TURN_TIME, MAX_TURN_TIME);
    setTimeout(() => {
      updateSpeed();
    }, nextSpeedChange);
  };

  useEffect(() => {
    canvasSizeRef.current = canvasSize;
    posRef.current = [
      Math.random() * canvasSize[0],
      Math.random() * canvasSize[1],
    ];
    setPosition(posRef.current);
  }, [canvasSize[0], canvasSize[1]]);

  useEffect(() => {
    let running = true;
    const updateLoop = () => {
      requestAnimationFrame(() => {
        const [canvasWidth, canvasHeight] = canvasSizeRef.current;
        posRef.current = [
          posRef.current[0] + velocityRef.current[0],
          posRef.current[1] + velocityRef.current[1],
        ];

        if (posRef.current[0] + 160 > canvasWidth) {
          velocityRef.current[0] = -velocityRef.current[0];
          posRef.current[0] = canvasWidth - 160;
        }
        if (posRef.current[0] < 0) {
          velocityRef.current[0] = -velocityRef.current[0];
          posRef.current[0] = 0;
        }
        if (posRef.current[1] + 160 > canvasHeight) {
          velocityRef.current[1] = -velocityRef.current[1];
          posRef.current[1] = canvasHeight - 160;
        }
        if (posRef.current[1] < 0) {
          velocityRef.current[1] = -velocityRef.current[1];
          posRef.current[1] = 0;
        }

        setPosition(posRef.current);

        if (running) {
          updateLoop();
        }
      });
    };

    updateSpeed();
    updateLoop();

    return () => {
      running = false;
    };
  }, []);

  if (canvasSize[0] === 0 && canvasSize[1] === 0) {
    return null;
  }

  return (
    <Container
      onPress={onPress}
      style={{
        transform: [{ translateX: position[0] }, { translateY: position[1] }],
      }}
    >
      <Image source={{ uri: image }} />
    </Container>
  );
};

export default CatchItem;

const Container = styled.TouchableOpacity`
  position: absolute;
`;

const Image = styled.Image`
  width: 160px;
  height: 160px;
  resize-mode: contain;
`;
