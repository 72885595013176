import React, { FC } from "react";
import { ActivityType, Age, IActivity } from "../types";
import CatchActivity from "./activity/CatchActivity";
import LabelActivity from "./activity/LabelActivity";
import MatchActivity from "./activity/MatchActivity";
import RightWrongImageActivity from "./activity/RightWrongImageActivity";
import RightWrongTextActivity from "./activity/RightWrongTextActivity";
import TestActivity from "./activity/TestActivity";

export interface IActivityProps {
  activity: IActivity;
  age?: Age;
  onCompleteChange: (complete: boolean, success: boolean) => void;
}

const Activity: FC<IActivityProps> = ({ activity, age, onCompleteChange }) => {
  switch (activity.type) {
    case ActivityType.Catch:
      return (
        <CatchActivity
          content={activity.content}
          onCompleteChange={onCompleteChange}
        />
      );

    case ActivityType.Label:
      return (
        <LabelActivity
          content={activity.content}
          age={age}
          onCompleteChange={onCompleteChange}
        />
      );

    case ActivityType.Match:
      return (
        <MatchActivity
          content={activity.content}
          onCompleteChange={onCompleteChange}
        />
      );

    case ActivityType.RightWrongImage:
      return (
        <RightWrongImageActivity
          content={activity.content}
          onCompleteChange={onCompleteChange}
        />
      );

    case ActivityType.RightWrongText:
      return (
        <RightWrongTextActivity
          content={activity.content}
          onCompleteChange={onCompleteChange}
        />
      );

    case ActivityType.Test:
      return (
        <TestActivity
          content={activity.content}
          onCompleteChange={onCompleteChange}
        />
      );

    default:
      return null;
  }
};

export default Activity;
