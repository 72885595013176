import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

function SvgClose(props: SvgProps) {
  return (
    <Svg viewBox="0 0 40 40" {...props}>
      <Path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.821 20l-9.41-9.41a.833.833 0 011.178-1.18L20 18.822l9.41-9.41a.833.833 0 011.18 1.178L21.178 20l9.41 9.41a.833.833 0 01-1.178 1.18L20 21.178l-9.41 9.41a.833.833 0 01-1.18-1.178L18.822 20z"
      />
    </Svg>
  );
}

export default SvgClose;
