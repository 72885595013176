import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

function SvgSolved(props: SvgProps) {
  return (
    <Svg viewBox="0 0 40 40" {...props}>
      <Path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.72 20.325a.83.83 0 00-1.183 0 .855.855 0 000 1.199l7.305 7.394a.83.83 0 001.184 0L32.463 12.28a.855.855 0 000-1.198.83.83 0 00-1.184 0l-15.845 16.04-6.713-6.797z"
      />
    </Svg>
  );
}

export default SvgSolved;
